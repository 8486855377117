import videojs from 'video.js';

const clip = {
  reel: document.querySelector('.js-reel'),
  players: document.querySelectorAll('.js-clip'),
};

for (let i = clip.players.length - 1; i >= 0; i -= 1) {
  videojs(clip.players[i]);
}
