import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

export function headingWrapperHeight(items) {
  let itemsWrapperHeight = 0;

  items.forEach((el) => {
    const heading = el;
    if (items.length > 1) {
      heading.style.position = 'absolute';
      heading.style.margin = '0';
    }
    if (itemsWrapperHeight < heading.offsetHeight) {
      itemsWrapperHeight = heading.offsetHeight;
      heading.style.height = `calc(${itemsWrapperHeight}px + 1rem)`;
    }
  });
}

export function headingIn(heading) {
  return gsap.to(heading.chars, {
    ease: 'Power3.easeOut',
    y: '0',
    opacity: 1,
    duration: 0.65,
    stagger: 0.014,
  });
}

export function headingOut(heading) {
  return gsap.to(heading.chars, {
    ease: 'Power3.easeIn',
    y: '-100%',
    duration: 0.65,
    stagger: 0.014,
  });
}

export function growToWindow(item) {
  return gsap.to(item, {
    width: window.innerWidth,
    x: () => `-=${(window.innerWidth - item.offsetWidth) / 2}`,
    scrollTrigger: {
      scrub: 1,
      trigger: item,
      start: 'clamp(top 66.66%)',
      end: 'clamp(bottom bottom)',
    },
  });
}

export function fadeToRead(item) {
  return gsap.to(item, {
    opacity: 0.2,
    scrollTrigger: {
      scrub: 1,
      trigger: item,
      start: 'clamp(top top)',
      end: 'clamp(bottom top)',
    },
  });
}

export function pinSection(wrapper, target) {
  return gsap.to(wrapper, {
    scrollTrigger: {
      trigger: wrapper,
      pin: target,
      start: `clamp(top 23%)`,
      end: 'bottom bottom',
      pinSpacing: false,
    },
  });
}

// export function horzSection(inner, parent) {
//   return gsap.to(inner, {
//     x: () => `${-(inner.scrollWidth - window.innerWidth)}px`,
//     ease: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)',
//     scrollTrigger: {
//       trigger: parent,
//       invalidateOnRefresh: true,
//       pin: true,
//       pinSpacing: true,
//       scrub: 0.25,
//       end: () => `+=${inner.offsetWidth}`,
//     },
//   });
// }

export function fadeUp(item) {
  return gsap.to(item, {
    opacity: 1,
    duration: 0.65,
    y: '0',
    onEnter: (i, el) => {
      if (el.classList.contains('c-hero__clip')) {
        const currClip = el.querySelector('video');
        currClip.muted = true;
        currClip.loop = true;
        currClip.play();
      }
    },
  });
}

export function teaseBackgroundShift(items) {
  items.forEach((el) => {
    const elImg = el.querySelector('.c-tease__media');
    if (elImg) {
      gsap.from(elImg, {
        ease: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)',
        y: () => `-=${elImg.offsetHeight - el.offsetHeight}`,
        scrollTrigger: {
          trigger: el,
          start: 'clamp(top, 80%)',
          scrub: true,
          invalidateOnRefresh: true,
        },
      });
    }
  });
}

export function teaseStaggerIn(items) {
  return ScrollTrigger.batch(items, {
    start: 'top 90%',
    once: true,
    onEnter: (batch) => {
      gsap.to(batch, {
        opacity: 1,
        y: 0,
        stagger: 0.4,
        ease: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)',
      });
    },
  });
}

export function staggerIn(items) {
  return ScrollTrigger.batch(items, {
    start: 'center bottom',
    once: true,
    onEnter: (batch) => {
      gsap.to(batch, {
        opacity: 1,
        y: 0,
        stagger: 0.4,
        ease: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)',
      });
    },
  });
}
