import { gsap } from 'gsap';
import Splitting from 'splitting';
import {
  pinSection,
  headingWrapperHeight,
  headingIn,
  headingOut,
  fadeUp,
  growToWindow,
} from './utils';

const pinWrap = document.querySelector('.js-pin-wrap');
const pinTarget = document.querySelector('.js-pin-target');
const heroClip = document.querySelector('.c-hero .c-clip');
const headings = document.querySelectorAll('.js-heading');
const revealItems = document.querySelectorAll('.js-reveal');
const maintimeline = gsap.timeline();

let splitHeadings;

function initTimeline() {
  if (pinWrap && pinTarget) {
    pinSection(pinWrap, pinTarget);
  }

  if (headings.length) {
    splitHeadings = Splitting({ target: headings });

    if (splitHeadings.length >= 1) {
      headingWrapperHeight(headings);
    }

    maintimeline.add(headingIn(splitHeadings[0]));

    if (splitHeadings.length > 1) {
      maintimeline.add(headingOut(splitHeadings[0]));
      maintimeline.add(headingIn(splitHeadings[1]));
    }
  }

  if (revealItems.length) {
    maintimeline.add(fadeUp(revealItems));
  }

  if (heroClip) {
    growToWindow(heroClip);
  }
}

export default initTimeline;
