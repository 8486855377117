// Hide scroll check items on scroll down
export default function scrollCheck() {
  const scrollCheckItems = document.querySelectorAll('.js-scroll-check');
  const mainMenu = document.querySelector('.c-nav.c-nav--main');

  let lastScrollTop = 0;
  let ticking = false;
  let hideThreshold = 100;

  if (document.body.classList.contains('home')) {
    hideThreshold =
      document.querySelector('.home .c-hero .c-clip').offsetTop +
      document.querySelector('.home .c-header').offsetHeight;
  }

  window.addEventListener('scroll', () => {
    if (!ticking) {
      window.requestAnimationFrame(() => {
        if (
          lastScrollTop < document.documentElement.scrollTop &&
          document.documentElement.scrollTop > hideThreshold
        ) {
          for (let i = 0; i < scrollCheckItems.length; i += 1) {
            if (!mainMenu.classList.contains('is-active')) {
              scrollCheckItems[i].classList.add('is-hidden');
            }
          }
        } else {
          for (let i = 0; i < scrollCheckItems.length; i += 1) {
            if (!mainMenu.classList.contains('is-active')) {
              scrollCheckItems[i].classList.remove('is-hidden');
            }
          }
        }
        ticking = false;
        lastScrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
      });
    }
    ticking = true;
  });
}
