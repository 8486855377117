import { teaseBackgroundShift, teaseStaggerIn, staggerIn } from './utils';

const InfiniteScroll = require('infinite-scroll');

const infWrapper = document.querySelector('.js-infinite-scroll');
const scrollrevealFooter = document.querySelector('.js-footer-scrollreveal');

function cleanUp(el) {
  el.destroy();
  staggerIn(scrollrevealFooter);
}

export default function initInfScroll(msnry = null) {
  let infScroll = null;

  if (infWrapper) {
    infScroll = new InfiniteScroll(infWrapper, {
      path: 'page/{{#}}',
      history: false,
    });
  }

  if (infScroll) {
    // Get items from body
    infScroll.on('load', (body) => {
      // Get items from body
      const items = body.querySelectorAll('.c-tease');

      if (items.length > 0) {
        // create new item elements
        const fragment = document.createDocumentFragment();

        // append elements to fragment
        for (let index = 0; index < items.length; index += 1) {
          fragment.appendChild(items[index]);
        }

        // append elements to container
        infWrapper.appendChild(fragment);

        // Stagger in new items
        teaseStaggerIn(items);

        // add and lay out newly appended elements
        if (msnry) {
          msnry.appended(items);
        }
      } else {
        // If no more items, clean up
        cleanUp(infScroll);
      }

      // Add background shift to talent teases
      if (items[0].classList.contains('c-tease--talent')) {
        teaseBackgroundShift(items);
      }
    });

    infScroll.on('last', () => {
      cleanUp(infScroll);
    });

    infScroll.on('error', () => {
      cleanUp(infScroll);
    });
  }
}
