import {
  // horzSection,
  teaseStaggerIn,
  teaseBackgroundShift,
  staggerIn,
} from './utils';

// const cards = document.querySelector('.c-cards');
// const cardsWrapper = document.querySelector('.c-cards__wrapper');
const scrollrevealItems = document.querySelectorAll('.js-scrollreveal');
const scrollrevealFooter = document.querySelector('.js-footer-scrollreveal');
const infWrapper = document.querySelector('.js-infinite-scroll');
const talentTease = document.querySelectorAll('.c-tease--talent');
const clipsTease = document.querySelectorAll('.c-tease--clips');

function initComponents() {
  // if (cardsWrapper) {
  //   horzSection(cardsWrapper, cards);
  // }

  if (scrollrevealItems.length) {
    teaseStaggerIn(scrollrevealItems);
  }

  if (talentTease.length) {
    teaseBackgroundShift(talentTease);
  }

  if (clipsTease.length) {
    teaseBackgroundShift(clipsTease);
  }

  // if scrollrevealFooter exists and infWrapper doesn't exist, stagger in scrollrevealFooter
  if (scrollrevealFooter && !infWrapper) {
    staggerIn(scrollrevealFooter);
  }
}

export default initComponents;
