import Masonry from 'masonry-layout';

export default function initMasonry() {
  let msnry = null;
  const masonryEl = document.querySelector('.js-masonry');

  if (masonryEl) {
    msnry = new Masonry(masonryEl, {
      itemSelector: '.c-tease',
      percentPosition: true,
      transitionDuration: 0,
    });
  }

  return msnry;
}
